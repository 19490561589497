jQuery(document).ready(function ($) {

	$('.portfolio-card').hover(
		function () {
			var video = $(this).find('.portfolio-card__video').get(0);

			if (video) {
				video.play();
			}
		},
		function () {
			var video = $(this).find('.portfolio-card__video').get(0);

			if (video) {
				setTimeout(function () {
					video.pause();
					video.currentTime = 0;
				}, 300);
			}
		}
	);


	$wrappersInitial = Array.from(document.querySelectorAll('.portfolio-card-home__image-slider--wrapper'));

	$sliderOptions = {
		threshold: 1
	}

	var $images = $($wrappersInitial[0]).children('img');
	var $maxIndex = $images.length - 1;
	var $currentIndex = $($wrappersInitial[0]).children('img.active').index();

	function interval(){
		if($currentIndex >= $maxIndex){
			$currentIndex = 0;
		}else{
			$currentIndex++;
		}
		$images.each(function(){
			$(this).removeClass('active');
		})
		$($images[$currentIndex]).addClass('active');
	}

	var masterInterval = setInterval(function(){interval()}, 4000);

	var sliderObserver = new IntersectionObserver(function($wrappers){
			$wrappers.forEach(function(wrapper){
				
				if(wrapper.isIntersecting){
					$currentIndex = $(wrapper.target).children('img.active').index();
					$images = $(wrapper.target).children('img');
					$maxIndex = $(wrapper.target).children('img').length - 1;
				}
			})
	
	}, $sliderOptions)

	if($wrappersInitial.length > 0){
		for($i = 0; $i < $wrappersInitial.length; $i++){
			sliderObserver.observe($wrappersInitial[$i])
		}
	}
	


});
